/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('@fortawesome/fontawesome-free/css/all.min.css');
require('../fancyapp/jquery.fancybox.min.css');
var pageStyle = document.body.dataset.style;
require('../scss/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

require('bootstrap');
require('../fancyapp/jquery.fancybox.min.js');
require('@fortawesome/fontawesome-free/js/all.js');

$(function () {
    $('[data-tooltip="true"]').tooltip();
});

require('datatables');
require('datatables/media/css/jquery.dataTables.min.css');
require('./_tables.js');
require('./_chat.js');
require('./_gallery.js');
// import PerfectScrollbar from 'perfect-scrollbar';
//
// const container = document.querySelector('.content-wrap');
// const ps = new PerfectScrollbar(container);

// import Push from 'push.js';

// Push.config({serviceWorker: '//serviceWorker.min.js'});

// Push.create("Hello world!", {
//     body: "How's it hangin'?",
//     icon: '/icon.png',
//     timeout: 4000,
//     onClick: function () {
//         window.focus();
//         this.close();
//     }
// });

// self.addEventListener('push', function (event) {
//     event.waitUntil(
//         self.registration.showNotification('ServiceWorker Cookbook', {
//             body: 'Alea iacta est',
//         })
//     );
// });

// TinyMCE
// #####################################################################################################################

// SCRIPTS
// #####################################################################################################################
$('document').ready(function () {
    // push.subscribe().then(subscription => {
    // });
    // push.unsubscribe().then(() => {
    // });
    // Push.create('Hello World!');
    // $('.send-push').on('click', function () {
    //     Push.create('Test!');
    // });


    $('.custom-file-input').on('change', function () {
        let self = $(this),
            selfVal = self.val().split('\\').pop(),
            label = self.next('label'),
            original = label.data('original');
        selfVal = (selfVal === '') ? original : selfVal;

        label.text(selfVal);
    });

    $('#loader').addClass('fadeout');

    $('.scroll-down').each(function () {
        var self = $(this),
            scrollTo = self.data('scroll');

        self.on('click', function () {
            $("html, body").delay(500).animate({
                scrollTop: $(scrollTo).offset().top
            }, 800);
        });
    });
});

$(window).scroll(function () {
    var scrollPos = $(document).scrollTop();
    if (scrollPos > 150) {
        $('nav.navbar').addClass('small').addClass('shadow-light').addClass('dark');
    } else {
        $('nav.navbar').removeClass('small').removeClass('shadow-light').removeClass('dark');
    }
});