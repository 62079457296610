$(document).ready(function () {
    $('body').on('change', '#uploadFile', function () {
        let self = $(this),
            gallery = self.data('gallery'),
            uri = self.data('uri'),
            data = new FormData();

        for (let i = 0; i < this.files.length; i++) {
            data = new FormData();
            data.append('files', this.files[i]);
            let progressbar = $('<div id="progress-' + i + '" class="progress-container">' + this.files[i].name + '<div class="progress">\n' +
                '  <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>\n' +
                '</div></div>');
            $(".fancybox-container #responses").append(progressbar);

            $.ajax({
                url: uri,
                data: data,
                type: 'POST',
                async: true,
                processData: false,
                contentType: false,
                xhr: function () {
                    var xhr = $.ajaxSettings.xhr();
                    xhr.upload.onprogress = function (evt) {
                        let prozent = (evt.loaded / evt.total * 100);
                        $('#progress-' + i + ' .progress-bar').attr('aria-valuenow', prozent).css('width', prozent + '%').text(prozent + '%');
                    };
                    xhr.upload.onload = function () {
                        setTimeout(function () {
                            $('#progress-' + i).fadeOut('500', function () {
                                $(this).remove();
                                $('.image-counter-' + gallery).text(parseInt($('.image-counter-' + gallery).text()) + 1);
                                if ($('.progress-container').length <= 0) {
                                    $.fancybox.destroy();
                                }
                            });
                        }, 1500);
                    };
                    return xhr;
                }
            });
        }
    });

    $('.addYoutube').on('click', function () {
        let self = $(this),
            input = self.parents('.add-youtube-to-gallery-container').find('.master-input'),
            master = input.clone(false).removeClass('master-input').val('');

        input.after(master);
    });

    $('.submitYoutube').on('click', function () {
        let self = $(this),
            form = self.parents('.add-youtube-to-gallery-container').find('form'),
            uri = form.data('uri');

        console.log(form.serialize());
        console.log(uri);

        $.ajax({
            url: uri,
            data: form.serialize(),
            type: 'POST',
            async: true,
            success: function (data) {
                console.log(data);
            }
        });
    });
});