$(document).on('click', '.chat_people', function () {
    var self = $(this).parent('.chat_list'),
        room = self.data('room'),
        msgHistory = $('.msg_history');

    $('.chat_list').removeClass('active_chat');
    self.addClass('active_chat');

    msgHistory.html('');
    $.ajax({
        url: room,
        async: true,
        success: function (data) {
            msgHistory.html(data);
            msgHistory.scrollTop(msgHistory.prop("scrollHeight"));
            $('.type_msg').removeClass('d-none');
            showAccess();
            $('.inbox_people').addClass('opened');
        }
    });
    return false;
});

$(document).on('click', '.msg_send_btn', sendMessage);

$(document).ready(function () {
    $('.write_msg').keyup(function (event) {
        if (event.keyCode === 13) {
            $(".msg_send_btn").click();
        }
    });
    $('.chat-room-option').on('click', function () {
        var self = $(this),
            room = self.data('room');
    });
    $('#chat .inbox_people .opener').on('click', function () {
        $('.type_msg').addClass('d-none');
        $('.chat_list').removeClass('active_chat');
        $('.inbox_people').removeClass('opened');
        $('.msg_history').html('');
    });
    $('.chat-show-access').on('click', function (event) {
        event.preventDefault();
        var self = $(this),
            room = self.data('path');
        $.ajax({
            url: room,
            async: true,
            success: function (data) {
                console.log(data);
                $.fancybox.open(data);
            },
        });
    });
    updateRooms();
    setInterval(function () {
        if ($('.active_chat').length > 0) {
            updateChat();
        }
    }, 1000);
    setInterval(function () {
        updateRooms();
    }, 5000);
});

function sendMessage() {
    var msgField = $('.write_msg'),
        msg = msgField.val(),
        room = $('.active_chat').data('room'),
        msgHistory = $('.msg_history');

    $.ajax({
        method: "POST",
        url: room,
        async: true,
        data: {msg: msg},
        success: function (data) {
            msgHistory.html(data);
            msgHistory.scrollTop(msgHistory.prop("scrollHeight"));
            $('.type_msg').removeClass('d-none');
            msgField.val('');
        },
    });
    return false;
}

function showAccess() {
    $('.msg_history .chat-show-access').on('click', function (event) {
        event.preventDefault();
        var self = $(this),
            room = self.data('path');
        $.ajax({
            url: room,
            async: true,
            success: function (data) {
                console.log(data);
                $.fancybox.open(data);
            },
        });
    });
}

function updateChat() {
    var self = $('.active_chat'),
        room = self.data('room'),
        msgHistory = $('.msg_history');

    $.ajax({
        url: room,
        async: true,
        success: function (data) {
            msgHistory.html(data);
            // msgHistory.scrollTop(msgHistory.prop("scrollHeight"));
            $('.type_msg').removeClass('d-none');
            showAccess();
            checkNewMessages();
        }
    });
    return false;
}

function updateRooms() {
    var chat = $('.inbox_chat'),
        uri = chat.data('rooms');

    $.ajax({
        url: uri,
        async: true,
        success: function (data) {
            chat.html(data);
            checkNewMessages();
        }
    });
    return false;
}

function checkNewMessages() {
    var chat = $('.inbox_chat'),
        uri = chat.data('check');

    $.ajax({
        url: uri,
        async: true,
        success: function (data) {
            if ($.isArray(data) && data.length > 0) {
                $('#navbarChat .badge').addClass('new');
            } else {
                $('#navbarChat .badge').removeClass('new');
            }
            for (var i = 0; i < data; i++) {
                if (data[i] > 0) {
                    $('.chat_list#' + data[i]).addClass('new');
                }
            }
        }
    });
    return false;
}